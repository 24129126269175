<template>
  <div>
    <div class="container">
      <div class="item__row item__ac">
        <button
          class="form__button mt-10 pointer mr-2"
          @click="$router.push('request/register')"
        >
          Заявки на регистрацию
        </button>
        <button class="form__button mt-10 pointer" @click="$router.push('moderation')">
          Модерация данных
        </button>
      </div>
      <div class="row">
        <div class="col">
          <div class="main-box-2" v-if="bankIsActive">
            <div class="option">
              <div class="header-button">
                <button
                  class="buttons"
                  :class="{ active: bankIsActive }"
                  @click="getBanks()"
                >
                  <i class="mdi mdi-database-outline mr-2"></i>
                  Реестр банков
                </button>
                <button
                  class="buttons"
                  :class="{ active: documentIsActive }"
                  @click="getDocument()"
                >
                  <i class="mdi mdi-folder-outline mr-2"></i>
                  Реестр договоров
                </button>
                <button
                  @click="getAdminUsers()"
                  class="buttons"
                  :class="{ active: adminUsersIsActive }"
                >
                  <i class="mdi mdi-account-outline mr-2"></i>
                  Администраторы
                </button>
              </div>
              <div class="configuration">
                <label for="search-input" class="input-box">
                  <input type="text" required id="search-input" v-model="search" placeholder="Поиск" />
                  <img src="../../assets/img/magnifier.svg" alt="" srcset="" />
                </label>
                <div class="radio-box">
                  <input
                    type="radio"
                    name="config"
                    id="new"
                    checked
                    @change="
                      () => {
                        sort = '-updated_at';
                        getBanks();
                      }
                    "
                  />
                  <label for="new">сначала новые</label>
                  <input
                    type="radio"
                    name="config"
                    id="old"
                    @change="
                      () => {
                        sort = 'updated_at';
                        getBanks();
                      }
                    "
                  />
                  <label for="old">сначала старые</label>
                </div>
              </div>
              <button class="download" @click="getExel()">Выгрузить в excel</button>
            </div>
            <div class="table">
              <v-data-table
                :headers="headers"
                :items="users"
                :page="page"
                :loading="loading"
                :options.sync="options"
                :server-items-length="totalPage"
                @click:row="getBank"
              >
							<template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <i
                      class="mdi mdi-eye mr-2 pointer"
                      @click="getBank(item)"
                      v-bind="attrs"
                      v-on="on"
                    ></i>
                  </template>
                  <span>Посмотреть</span>
                </v-tooltip>
              </template>
                <!-- <template v-slot:item.role.name="{ item }">
                  <p class="mb-0" v-if="item.role.name == 'CEO'">Директор</p>
                  <p class="mb-0" v-else>Сотрудник</p>
                </template> -->
              </v-data-table>
            </div>
          </div>
          <div class="main-box-2" v-if="documentIsActive">
            <div class="option">
              <div class="header-button">
                <button
                  class="buttons"
                  :class="{ active: bankIsActive }"
                  @click="getBanks()"
                >
                  <i class="mdi mdi-database-outline mr-2"></i>
                  Реестр банков
                </button>
                <button
                  class="buttons"
                  :class="{ active: documentIsActive }"
                  @click="getDocument()"
                >
                  <i class="mdi mdi-folder-outline mr-2"></i>
                  Реестр договоров
                </button>
                <button
                  @click="getAdminUsers()"
                  class="buttons"
                  :class="{ active: adminUsersIsActive }"
                >
                  <i class="mdi mdi-account-outline mr-2"></i>
                  Администраторы
                </button>
              </div>

              <div class="configuration-restr">
                <label for="search-input" class="input-box search">
                  <input type="text" required id="search-input" v-model="searchDoc" placeholder="Поиск"/>
                  <img src="../../assets/img/magnifier.svg" alt="" srcset="" />
                </label>
                <label for="search-input" class="input-box type">
                  <input
                    readonly
										placeholder="Выберите договор"
                    type="text"
                    id="search-input"
                    @click="docBoxIsActive = !docBoxIsActive"
                    v-model="selecDocType"
                    @keyup.delete="deleteInputdoc()"
                  />
                  <img src="../../assets/img/arraw-down.svg" alt="" srcset="" />
                  <div class="banks-box" v-if="docBoxIsActive">
                    <ul>
                      <li
                        v-for="item of dataDocType"
                        :key="item.id"
                        @click="clickDocType(item.name, item.title)"
                      >
                        {{ item.title }}
                      </li>
                    </ul>
                  </div>
                </label>
                <label for="search-input " class="input-box bank">
                  <input
									placeholder="Выберите организацию"
                    readonly
                    type="text"
                    id="search-input"
										
                    @click="bankBoxIsActive = !bankBoxIsActive"
                    v-model="selectBank"
                    @keyup.delete="deleteInputbank()"
                  />
                  <img src="../../assets/img/arraw-down.svg" alt="" srcset="" />
                  <div class="banks-box" v-if="bankBoxIsActive">
                    <ul>
                      <li
                        v-for="item of DataOrganizations"
                        :key="item.id"
                        @click="clickBank(item.id, item.name)"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </label>

                <label for="search-input " class="input-box period">
                  <input
                    readonly
										placeholder="Выберите период"
                    type="text"
                    id="search-input"
                    @click="calendarIsActive = !calendarIsActive"
                    v-model="DocDates"
                  />
                  <div class="window" v-if="calendarIsActive">
                    <div class="date-picker">
                      <v-date-picker range v-model="DocDates"></v-date-picker>
                      <button class="date-all-time" @click="deleteDocDates()">
                        за все время
                      </button>
                    </div>
                  </div>

                  <img src="../../assets/img/calendar.svg" alt="" srcset="" />
                </label>
              </div>
              <button class="download" @click="getExelDoc()">Выгрузить в excel</button>
							<button class="reset--btn pointer" @click="resetFilters()">Сбросить</button>
            </div>

            <div class="table">
              <v-data-table
                :headers="headersDoc"
                :items="usersDoc"
                :page="pageDoc"
                :loading="loading"
                :options.sync="optionsDoc"
                :server-items-length="totalPageDoc"
              >
              </v-data-table>
            </div>
          </div>
          <div class="main-box-2" v-if="adminUsersIsActive">
            <div class="option">
              <div class="header-button">
                <button
                  class="buttons"
                  :class="{ active: bankIsActive }"
                  @click="getBanks()"
                >
                  <i class="mdi mdi-database-outline mr-2"></i>
                  Реестр банков
                </button>
                <button
                  class="buttons"
                  :class="{ active: documentIsActive }"
                  @click="getDocument()"
                >
                  <i class="mdi mdi-folder-outline mr-2"></i>
                  Реестр договоров
                </button>
                <button
                  @click="getAdminUsers()"
                  class="buttons"
                  :class="{ active: adminUsersIsActive }"
                >
                  <i class="mdi mdi-account-outline mr-2"></i>
                  Администраторы
                </button>
              </div>
              <AdminCreate
                :adminData="adminData"
                :loadingActive="loading"
                @search="serchUserInput($event)"
              />
            </div>

            <div class="table">
              <v-data-table
                :headers="headersUser"
                :items="usersUser"
                :page="page"
                :loading="loading"
                :options.sync="optionsUser"
                :server-items-length="totalPageUser"
              >
                <template v-slot:item.admin="{ item }">
                  <div v-if="item.admin" class="status-active">Активный</div>
                  <div v-else class="status-block">Заблокированый</div>
                </template>
                <template v-slot:item.operation="{ item }">
                  <div class="df">
                    <img
                      src="../../assets/img/delete.svg"
                      alt=""
                      class=""
                      @click="deleteAdmin(item)"
                    /><img
                      src="../../assets/img/pencil.svg"
                      alt=""
                      class=""
                      @click="putAdmin(item)"
                    />
                  </div>
                </template>
              </v-data-table>
              <ModalDeleteAdmin
                @active="showModal(false)"
                v-model="modalIsActive"
                v-if="modalIsActive"
                :adminInfo="adminInfo"
              />
              <ModalAdminCreate
                v-if="modalIsActivePut"
                v-model="modalIsActivePut"
                @active="showModalPut(false)"
                :adminData="adminInfo"
                :type="type"
                :config="{
                  headerText: 'Изменить администратора',
                  buttonText: 'Сохранить',
                  option: 'change',
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminCreate from "../../components/AdminCreate";
import ModalDeleteAdmin from "../../components/ModalDeleteAdmin";
import ModalAdminCreate from "../../components/ModalAdminCreate";
// import html2pdf from 'html2pdf.js';

export default {
  components: {
    AdminCreate,
    ModalDeleteAdmin,
    ModalAdminCreate,
  },
  data() {
    return {
      type: null,
      adminInfo: {},
      adminSearch: "",
      modalIsActive: false,
      modalIsActivePut: false,
      search: "",
      sort: "updated_at",
      page: 0,
      totalPage: 0,
      numberOfPages: 0,
      options: {
        itemsPerPage: 5,
        page: 1,
				sortBy: [],
				sortDesc: [false]
      },
      users: [],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Название",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Адрес", value: "address", sortable: false  },
        { text: "Телефон", value: "phone", sortable: false },
        { text: "E-mail", value: "email", sortable: false  },
        { text: "БИН", value: "bin", sortable: false  },
        { text: "Дата регистрации", value: "created_at" },
        { text: "Действие", value: "action", sortable: false  },
      ],
      headersDoc: [
        {
          text: "Номер",
          sortable: false,
          value: "id",
        },
        {
          text: "Наименование",
          value: "type.title",
          sortable: false,
        },
        {
          text: "Дата ",
          value: "created_at",
          sortable: false,
        },
        {
          text: "От кого",
          value: "user.name",
          sortable: false,
        },
        {
          text: "Статус",
          value: "stage.title",
          sortable: false,
        },
        // {
        //   text: "Действия",
        //   sortable: false,
        // },
      ],
      usersDoc: [],
      pageDoc: 0,
      pageUser: 0,
      optionsDoc: {
        itemsPerPage: 5,
        page: 1,
      },
      optionsUser: {
        itemsPerPage: 5,
        page: 1,
      },
      totalPageDoc: 0,
      searchDoc: "",
      DataOrganizations: [],
      organization_id: "",
      docType: "",
      DocDate: {
        start_date: "",
        end_date: "",
      },
      pagination: {},
      loading: false,
      statuses: [],
      status: "",
      bankIsActive: true,
      bankBoxIsActive: false,
      selectBank: "",
      dataDocType: [],
      docBoxIsActive: false,
      selecDocType: "",
      DocDates: ["", ""],
      calendarIsActive: false,
      adminData: {},
      documentIsActive: false,
      adminUsersIsActive: false,
      headersUser: [
        {
          text: "ФИО",
          sortable: false,
          value: "common_name",
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "Телефон ",
          value: "phone",
          sortable: false,
        },
        {
          text: "Статус",
          value: "admin",
          sortable: false,
        },
        {
          text: "",
          value: "operation",
          align: "End",
          sortable: false,
        },
      ],
      totalPageUser: 0,
      usersUser: [],
    };
  },
  methods: {
    callAdminUsers() {
      this.bankIsActive = false;
      this.documentIsActive = false;
      this.adminUsersIsActive = true;
      this.getAdminUsers();
    },
    showModal(active) {
      this.modalIsActive = active;
      this.getAdminUsers();
    },
    showModalPut(active) {
      this.modalIsActivePut = active;
      this.getAdminUsers();
    },
    deleteAdmin(admin) {
      this.showModal(true);
      console.log(admin);
      this.adminInfo = admin;
    },
    putAdmin(admin) {
      this.modalIsActivePut = true;
      this.adminInfo = admin;
      this.type = "update";
    },
    serchUserInput(text) {
      console.log(text.search);
      this.adminSearch = text.search;
      this.getAdminUsers();
    },
    getBanks() {
      this.bankIsActive = true;
      this.documentIsActive = false;
      this.adminUsersIsActive = false;
      this.loading = true;

      this.$axios({
        method: "get",
        url:
          this.$API_URL +
          this.$API_VERSION +
          `admin/organizations?page=${this.options.page}&per_page=${this.options.itemsPerPage}&sort=` + 	`${this.options.sortDesc[0] === false ? "-updated_at" : 'updated_at'}` + `&search=${this.search}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.loading = false;
          this.numberOfPages = response.data.meta.current_page;
          this.totalPage = response.data.meta.total;

          this.users = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    getExel() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "admin/organizations/export",
        responseType: "arraybuffer",
        data: this.sobs,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          console.log(response.data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "organizations.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getExelDoc() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "admin/documents/export",
        responseType: "arraybuffer",
        data: this.sobs,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          console.log(response.data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "document.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllorganizations() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + `admin/organizations?per_page=-1`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      }).then((response) => {
        this.DataOrganizations = response.data.data;
      });
    },
    getDocument() {
      try {
        this.bankIsActive = false;
        this.documentIsActive = true;
        this.adminUsersIsActive = false;

        this.$axios({
          url:
            this.$API_URL +
            this.$API_VERSION +
            `admin/documents?page=${this.optionsDoc.page}&per_page=${this.optionsDoc.itemsPerPage}&sort=-updated_at&search=${this.searchDoc}&organization_id=${this.organization_id}&type=${this.docType}&start_date=${this.DocDates[0]}&end_date=${this.DocDates[1]}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
          },
        })
          .then((response) => {
            this.loading = false;

            this.numberOfPages = response.data.meta.current_page;
            this.totalPageDoc = response.data.meta.total;

            this.usersDoc = response.data.data;
          })
          .catch((error) => {
            console.warn(error);
          });
      } catch (error) {
        console.error(error);
      }
    },
    getBank(row) {
      this.$router.push({ name: "BankInform", params: { bankId: row.id } });
    },
    clickBank(id, name) {
      this.organization_id = id;
      this.selectBank = name;
      this.getDocument();
      this.bankBoxIsActive = false;
    },
    allTypeDocument() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + `types`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      }).then((response) => {
        this.dataDocType = response.data.data;
        console.log("this.dataDocType", this.dataDocType);
      });
    },
    getAdminUsers() {
      // try {
      this.bankIsActive = false;
      this.documentIsActive = false;
      this.adminUsersIsActive = true;
      this.$axios({
        method: "get",
        url:
          this.$API_URL +
          this.$API_VERSION +
          `admin/admins?page=${this.optionsUser.page}&per_page=${this.optionsUser.itemsPerPage}&search=${this.adminSearch}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      }).then((response) => {
        this.numberOfPages = response.data.meta.current_page;
        this.totalPageUser = response.data.meta.total;

        this.usersUser = response.data.data;
      });
      // } catch (error) {
      //   console.error(error);
      // }
    },
    clickDocType(type, name) {
      this.selecDocType = name;
      this.docType = type;
      this.docBoxIsActive = false;
      this.getDocument();
    },
    deleteInputdoc() {
      this.selecDocType = "";
      this.docType = "";
      this.docBoxIsActive = false;
      this.getDocument();
    },
    deleteInputbank() {
      this.organization_id = "";
      this.selectBank = "";
      this.getDocument();
      this.bankBoxIsActive = false;
    },
    deleteDocDates() {
      this.DocDates = ['', ''];
      this.calendarIsActive = false;
      this.getDocument();
    },
		
		resetFilters() {
			this.DocDates = ['', ''];
      this.searchDoc = '';
      this.organization_id = '';
			this.selectBank = '';
			this.docType = '';
			this.selecDocType = '';
			this.getDocument();
    }
  },
  mounted() {
    this.getBanks();
    this.getAllorganizations();
    this.allTypeDocument();
  },
  watch: {
    options: {
      handler(val) {
        if (val.itemsPerPage < 0) {
          val.itemsPerPage = this.totalPage;
          this.getBanks();
        } else {
          this.getBanks();
        }
      },
    },
    optionsDoc: {
      handler(val) {
        if (val.itemsPerPage < 0) {
          val.itemsPerPage = this.totalPage;
          this.getDocument();
        } else {
          this.getDocument();
        }
      },
    },
    optionsUser: {
      handler(val) {
        if (val.itemsPerPage < 0) {
          val.itemsPerPage = this.totalPage;
          this.getAdminUsers();
        } else {
          this.getAdminUsers();
        }
      },
      deep: true,
    },
    search() {
      this.getBanks();
    },
    status() {
      this.getBanks();
    },
    searchDoc() {
      this.getDocument();
    },
    DocDates() {
      if (this.DocDates.length > 1) {
        this.calendarIsActive = false;
        this.getDocument();
      }
    },
    deep: true,
  },
};
</script>
<style lang="scss">
.main-box-2 {
  font-family: Gilroy;
  margin-top: 64px;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.04);
  border-radius: 9px;
  position: relative;
  padding: 74px 42px 51px 42px;

  .option {
    display: flex;
    margin-bottom: 30px;
    align-items: baseline;
    .download {
      margin-left: auto;
      background: #aa7530;
      border-radius: 39px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      padding: 10px 35px;
      color: #ffffff;
    }
    .configuration {
      display: flex;
      align-items: center;

      .input-box {
        background: #f9f9f9;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 9px;
        width: 705px;
        white-space: nowrap;
        padding: 9px 10px;
        margin-right: 47px;

        input {
          width: 96%;
        }
        img {
          margin-right: auto;
        }
      }
    }
    .configuration-restr {
      display: flex;
      align-items: center;
			.search {
        width: 225px !important;
        position: relative;
      }
      .type {
        width: 225px !important;
        position: relative;
			cursor: pointer;
      }
      .bank {
        width: 225px !important;
        position: relative;
				cursor: pointer;
      }
      .period {
        width: 230px !important;
				cursor: pointer;
      }
      .input-box {
        background: #f9f9f9;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 9px;
        width: 313px;
        white-space: nowrap;
        padding: 9px 10px;

        input {
          width: 90%;
          margin-right: 6px;
        }
        img {
          margin-right: auto;
        }
      }
      label {
        margin-right: 10px;
      }

      .radio-box {
        label {
          margin-right: 28px;
          &:hover {
            cursor: pointer;
          }
        }
        font-size: 14px;
        color: #edf0f5;
      }
    }
    .header-button {
      font-family: Gilroy;
      position: absolute;
      left: 50%;
      width: min-content;
      right: 50%;
      display: flex;
      top: 0;
      transform: translateY(-50%) translateX(-50%);
    }
    & .buttons {
      font-weight: 600;
      transition: 0.3 ease;
      margin-right: 10px;
      padding: 16px 40px;
      box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.04);
      border-radius: 45px;
      white-space: nowrap;
      img {
        margin-right: 5px;
        path {
          fill: white;
        }
      }
    }
    .active {
      transition: 0.5s ease;
      background: #135334;
      color: #fff;
      fill: white;
      i {
        color: white;
      }
    }
  }
  .table {
    width: 100%;
    display: grid;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    thead {
      font-size: 16px;
      line-height: 20px;

      color: #1e2321;
    }
    tbody {
      tr {
        font-weight: 400;
        td:nth-child(2) {
          font-weight: 600;
          color: #135334;
        }
        &:hover {
          cursor: pointer;
        }
      }
      tr:nth-child(odd) {
        background: #f6f6f6;
      }
    }
  }
}
.radio-box {
  label {
    margin-right: 28px;
    &:hover {
      cursor: pointer;
    }
  }
  input {
    display: none;
  }
  input[type="radio"]:checked + label {
    color: #135334;
  }
}
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
.doc-name {
  color: #1e2321 !important;
}
.banks-box {
  position: absolute;
  top: 120%;
  left: 0;
  background: white;
	z-index: 1;
  ul {
    margin: 8px;
    padding: 0;
    li {
      list-style-type: none;
      &:hover {
        background: wheat;
        cursor: pointer;
      }
    }
  }
}
.window {
  background: rgba(0, 0, 0, 0.36);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  .date-picker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .date-all-time {
      // margin:auto;
      margin-top: 8px;
      background: rgb(25, 118, 210);
      padding: 8px;
      width: 100%;
      color: white;
    }
  }
}
.status-active {
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* 135334 */

  color: #135334;
}
.status-block {
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* 135334 */

  color: #135334;
}
.df {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  img {
    margin-right: 21px;
  }
}
th {
	white-space: nowrap;
}
</style>
