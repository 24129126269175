<template>
  <div>
    <div class="window" v-on:click.self.prevent="hideModal()">
      <div class="modal-user">
        <div class="head-div">
          <span> Удалить администратора</span>
          <img src="./../assets/img/x.svg" alt="" srcset="" @click="hideModal()" />
        </div>
        <div class="inputbox">
          <p v-if="adminInfo.admin" style="color: #135334">Активный</p>
          <p v-else style="color: #dc3333">Заблокированый</p>
        </div>
        <div class="inputbox">
          <p class="title">Имя</p>
          <p class="inputbox-input">{{ adminInfo.common_name }}</p>
        </div>
        <div class="inputbox">
          <p class="title">ИИН</p>
          <p class="inputbox-input">{{ adminInfo.iin }}</p>
        </div>
        <div style="text-align: center; width: 100%">
          <button class="user download userbutton" @click="deleteAdmin()">
            <img
              src="./../assets/img/user-icon.svg"
              alt=""
              class=""
              style="filter: invert(1)"
            />
            Удалить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["modalIsActive", "adminInfo"],
  data() {
    return {
      user: { check: false },
    };
  },
  methods: {
    hideModal() {
      this.$emit("active", { active: this.modalIsActive });
    },
    deleteAdmin() {
      try {
        this.$axios({
          method: "delete",
          url: this.$API_URL + this.$API_VERSION + "admin/admins/" + this.adminInfo.id,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
          },
        }).then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          console.log(response.data);
          this.$emit("active", { active: this.modalIsActive });
        });
      } catch (error) {
        console.error(error);
        alert("Ошибка на сервере");
      }
    },
  },
};
</script>
<style lang="scss" closed>
.window {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.349);
  position: fixed;
}
.modal-user {
  position: absolute;
  padding: 30px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  width: 706px;
  height: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.head-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
  font-size: 18px !important;
  font-family: Gilroy !important;
  font-weight: bold !important;
}
.inputbox {
  margin-bottom: 10px !important;
  .title {
    font-family: Gilroy !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;

    /* 1E2321 */
    margin-right: 10px !important;
    color: #1e2321 !important;
  }
  .inputbox-input {
    background: #ffffff !important;
    /* BCC6D7 */
    width: 100%;
    padding: 10px 20px !important;
    border: 1px solid #bcc6d7;
    box-sizing: border-box !important;
    border-radius: 9px !important;
  }
}
.doubleinput {
  display: flex;
  justify-content: space-between;

  &:first-child {
    margin-right: 30px;
  }
  .inputbox {
    &:first-child {
      margin-right: 30px;
    }
    width: 100%;
  }
}
.checkbox-user {
  input {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    /* 1E2321 */

    color: #1e2321;
    margin-right: 20px;
  }
  span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    /* 1E2321 */

    color: #1e2321;
  }
}
.userbutton {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
</style>
