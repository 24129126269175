<template>
  <div>
    <div class="window" v-on:click.self.prevent="hideModal()">
      <div class="modal-user">
        <div class="head-div">
          <span> {{ config.headerText }}</span>
          <img
            class="pointer"
            src="./../assets/img/x.svg"
            alt=""
            srcset=""
            @click="hideModal()"
          />
        </div>
        <div class="inputbox">
          <p class="title">Имя</p>
          <input type="text" required class="inputbox-input" :class="{ error: isError('name') }" v-model="info.common_name" />
        </div>
        <div class="inputbox">
          <p class="title">Фамилия</p>
          <input type="text" required class="inputbox-input" :class="{ error: isError('name') }" v-model="info.last_name" />
        </div>
        <div class="inputbox">
          <p class="title">ИИН</p>
          <input type="text" required class="inputbox-input" :class="{ error: isError('name') }" maxlength="12" v-model="info.iin" />
        </div>
        <div class="doubleinput">
          <div class="inputbox">
            <p class="title">Email</p>
            <input type="email" class="inputbox-input" :class="{ error: isError('name') }" v-model="info.email" />
          </div>
          <div class="inputbox">
            <p class="title">Телефон</p>
            <input type="text" required class="inputbox-input" :class="{ error: isError('name') }" v-model="info.phone" />
          </div>
        </div>
        <div class="inputbox">
          <p class="title">Пароль</p>
          <input type="text" required class="inputbox-input" :class="{ error: isError('name') }" v-model="info.password" />
        </div>
        <div class="inputbox">
          <p class="title">Роль</p>
          <v-select
            :items="roles"
            outlined
            :hide-details="true"
            :flat="true"
            item-text="name_ru"
            item-value="name"
            item-color="#000"
            dense
            v-model="info.role"
            required
            :class="{ error: isError('name') }"
          ></v-select>
        </div>
        <!-- <div class="checkbox-user">
          <input type="checkbox" name="" id="" v-model="info.send_mail" />
          <span>Отправить на почту доступ</span>
        </div> -->
        <div style="text-align: center; width: 100%">
          <button
            class="user download userbutton"
            @click="createAdmin()"
            v-if="config.option == 'create'"
          >
            <img
              src="../assets/img/user-icon.svg"
              alt=""
              class=""
              style="filter: invert(1)"
            />
            {{ config.buttonText }}
          </button>
          <button
            class="user download userbutton"
            @click="changeAdmin()"
            v-if="config.option == 'change'"
          >
            {{ config.buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["modalIsActive", "adminData", "config", "type"],
  data() {
    return {
      info: {
        common_name: "",
        last_name: "",
        otch: "",
        email: "",
        phone: "",
        password: "",
        iin: "",
        check: false,
        id: "",
        role: "",
      },
      roles: [],
      errors: {
        name: [],
        surname: [],
        iin: [],
        email: [],
        phone: [],
        password: [],
        role: [],
      },
    };
  },
  computed: {
    isError() {
      return (field) => this.errors[field].length > 0
    },
    isFormValid() {
      return Object.keys(this.errors).every((k) => !this.isError(k))
    },
  },
  methods: {
    checkName() {
      if (!this.info.common_name || this.info.common_name.length <= 0) {
        this.errors.name.push('Поле "Имя" обязательно для заполнения.')
        return
      } 
    },
    checkSurname() {
      if (!this.info.last_name || this.info.last_name.length <= 0) {
        this.errors.surname.push('Поле "Фамилия" обязательно для заполнения.')
        return
      } 
    },
    checkIIN() {
      if (!this.info.iin) {
        this.errors.iin.push('Поле "ИИН" обязательно для заполнения.')
        return
      }
      if (!this.info.iin || this.info.iin.length < 12) {
        this.errors.iin.push('Поле "ИИН" должно быть не короче 12 символов.')
      }
    },
    checkMail() {
      if (!this.info.email || this.info.email.length <= 0) {
        this.errors.email.push('Поле "Email" обязательно для заполнения.')
        return
      }
      if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(this.info.email)) {
        this.errors.email.push('Поле "Email" должно быть действительным электронным адресом.')
        return
      }
    },
    checkPhone() {
      if (!this.info.phone) {
        this.errors.phone.push('Поле "Телефон" обязательно для заполнения.')
        return
      }
    },
    checkPassword() {
      if (!this.info.password || this.info.password.length <= 0) {
        this.errors.password.push('Поле "Пароль" обязательно для заполнения.')
        return
      }
      if (!this.info.password || this.info.password.length < 8) {
        this.errors.password.push('Поле "Пароль" должно быть не короче 8 символов.')
      }
      // if (!/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?\/~_+-=|\]).{8,32}$/.test(this.info.password)) {
      //   this.errors.password.push('Пароль должен содержать по крайней мере одну заглавную и одну строчную букву. Пароль должен содержать по крайней мере один символ. Пароль должен содержать по крайней мере одно число.')
      //   return
      // }
    },
    checkRole() {
      if (!this.info.role || this.info.role.length <= 0) {
        this.errors.role.push('Поле "Роль" обязательно для заполнения.')
        return
      }
    },
    checkFields() {
      this.checkName();
      this.checkSurname();
      this.checkIIN();
      this.checkMail();
      this.checkPhone();
      this.checkPassword();
      this.checkRole();
    },
    getRoles() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "admin/roles",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.roles = response.data;
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          console.log(errors);
        });
    },
    hideModal() {
      this.$emit("active", { active: this.modalIsActive });
    },
    showErrors(errors) {
      let msg = ''
      for (let key in errors) {
        if (errors[key] && Array.isArray(errors[key]) && errors[key][0]) msg += errors[key][0] + '<br>'
      }
      this.$toast.open({
        message: msg,
        type: "error",
        position: "bottom",
        duration: 4000,
        queue: true,
      });
    },
    clearErros() {
      Object.keys(this.errors).forEach(key => this.errors[key] = [])
    },
    createAdmin() {
      this.checkFields()
      if (!this.isFormValid) {
        this.showErrors(this.errors)
        this.clearErros()
        return
      }
      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "admin/admins",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          common_name: this.info.common_name,
          last_name: this.info.last_name,
          email: this.info.email,
          iin: this.info.iin,
          password: this.info.password,
          phone: this.info.phone,
          send_mail: this.info.send_mail,
          role: this.info.role,
        },
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.$emit("active", { active: this.modalIsActive });
          this.$parent.callAdminUsers();
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          if (errors) this.showErrors(errors)
        });
    },
    changeAdmin() {
      this.$axios({
        method: "put",
        url: this.$API_URL + this.$API_VERSION + "admin/admins/" + this.info.id,
        data: {
          common_name: this.info.common_name,
          last_name: this.info.last_name,
          email: this.info.email,
          iin: this.info.iin,
          password: this.info.password,
          phone: this.info.phone,
          role: typeof this.info.role == "object" ? this.info.role.name : this.info.role,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.$emit("active", { active: this.modalIsActive });
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          if (errors) this.showErrors(errors)
        });
    },
  },
  mounted() {
    this.getRoles();
    this.info.common_name = this.adminData?.common_name;
    this.info.last_name = this.adminData?.last_name;
    this.info.email = this.adminData?.email;
    this.info.password = this.adminData?.password;
    this.info.iin = this.adminData?.iin;
    this.info.id = this.adminData?.id;
    this.info.role = this.adminData?.role;
    this.info.phone = this.adminData?.phone;
  },
};
</script>
<style lang="scss" closed>
.window {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.349);
  position: fixed;
  z-index: 1000;
}
.modal-user {
  position: absolute;
  padding: 30px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  width: 706px;
  height: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.head-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
  font-size: 18px !important;
  font-family: Gilroy !important;
  font-weight: bold !important;
}
.inputbox {
  margin-bottom: 10px !important;
  .title {
    font-family: Gilroy !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;

    /* 1E2321 */
    margin-right: 10px !important;
    color: #1e2321 !important;
  }
  .inputbox-input {
    background: #ffffff !important;
    /* BCC6D7 */
    width: 100%;
    padding: 10px 20px !important;
    border: 1px solid #bcc6d7;
    box-sizing: border-box !important;
    border-radius: 9px !important;
  }
}
.doubleinput {
  display: flex;
  justify-content: space-between;

  &:first-child {
    margin-right: 30px;
  }
  .inputbox {
    &:first-child {
      margin-right: 30px;
    }
    width: 100%;
  }
}
.checkbox-user {
  input {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    /* 1E2321 */

    color: #1e2321;
    margin-right: 20px;
  }
  span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    /* 1E2321 */

    color: #1e2321;
  }
}
.userbutton {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
.user {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #ffffff !important;

  background: #2a79f0 !important;
  border-radius: 39px !important;
  padding: 10px 20px !important;
}
</style>
