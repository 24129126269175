<template>
  <div class="full-components">
    <div class="configuration">
      <label for="search-input" class="input-box">
        <input placeholder="Поиск" type="text" required id="search-input" v-model="search" />
        <img src="../assets/img/magnifier.svg" alt="" srcset="" />
      </label>
    </div>
    <button class="download" @click="getExel()">Выгрузить в excel</button>
    <button class="user download pointer" @click="showModal(true)">
      <img src="../assets/img/user-icon.svg" alt="" class="" style="filter: invert(1)" />
      Добавить нового
    </button>
    <ModalAdminCreate
      v-if="modalIsActive"
      v-model="modalIsActive"
      @active="showModal(false)"
      :adminData="adminData"
      :config="{
        headerText: 'Добавление нового администратора',
        buttonText: 'Добавить нового',
        option: 'create',
      }"
    />
  </div>
</template>
<script>
import ModalAdminCreate from "./ModalAdminCreate";
export default {
  props: ["loadingActive", "adminData"],
  components: { ModalAdminCreate },
  data() {
    return {
      search: "",
      modalIsActive: false,
    };
  },
  methods: {
    callAdminUsers() {
      this.$parent.getAdminUsers();
    },
    getExel() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "admin/admins/export",
        responseType: "arraybuffer",
        data: this.sobs,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          console.log(response.data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Users.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showModal(active) {
      this.modalIsActive = active;
    },
  },
  watch: {
    options: {
      handler() {
        this.getBanks();
      },
    },
    search() {
      this.$emit("search", { search: this.search });
    },
  },
};
</script>
<style lang="scss" scoped>
.full-components {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: baseline;
  align-content: center;
  justify-content: space-between;
}
.user {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #ffffff !important;

  background: #2a79f0 !important;
  border-radius: 39px !important;
  padding: 10px 20px !important;
}
</style>
